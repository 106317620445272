import { Drawer, Icon, IconButton, Modal } from "@material-ui/core";
import { WalletRepository } from "Infrastructure/repositories/api/WalletRepository";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { useUi } from "application/contexts/ui/UIContext";
import { TecladoVirtual } from "components/tecladoVirtual/TecladoVirtual";
import { ICardReaderStore } from "modules/cardReader/domain/interfaces/IUseCardReaderStore";
import { useCardReaderStore } from "modules/cardReader/infra/store/CardReaderStore";
import { WalletPaymentUseCase } from "modules/cashless/application/useCases/WalletPaymentUseCase";
import { IOrder } from "modules/order/domain/models/IOrder";
import { IWalletSyncTransaction } from "modules/payment/domain/models/IWalletTransaction";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { createDefferedPromise } from "utils/DefferedPromise";
import { GetError } from "utils/GetError";
import styles from "./Wallet.module.scss";
import { v4 } from "uuid";

export interface IWalletReturn {
  walletTransaction: IWalletSyncTransaction;
  order: IOrder;
}

export interface IWalletHandler {
  open(order: IOrder): Promise<IWalletReturn>;
}

interface IWalletProps {}

const UseWallet: React.ForwardRefRenderFunction<
  IWalletHandler,
  IWalletProps
> = ({}, ref) => {
  const deffered = useRef(createDefferedPromise<IWalletReturn>());

  const { toast, showLoading, hideLoading } = useUi();
  const { addLog } = useAppInsights();
  const { kiosk } = useKiosk();

  const [visible, setVisible] = useState(false);
  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [showPasswordPicker, setShowPasswordPicker] = useState(false);
  const [password, setPassword] = useState("");

  const walletTag = useRef<string | null>(null);
  const enabled = useRef(false);

  const [_enableSensor, disableSensor] = useCardReaderStore(
    (state: ICardReaderStore) => [state.enableSensor, state.disableSensor]
  );

  useEffect(() => {
    const unsubscribe = useCardReaderStore.subscribe(
      (state: ICardReaderStore) => state.tag,
      async (newTag: string | null) => {
        if (!newTag) {
          unsubscribe();
          return;
        } else {
          if (enabled.current) {
            setShowPasswordPicker(true);
            walletTag.current = newTag;
          }
        }
      }
    );
  }, []);

  const enableSensor = useCallback(async () => {
    try {
      await _enableSensor("Wallet");
      addLog("CashlessStore", { message: "Sensor habilitado para Wallet" });
    } catch (error) {
      enabled.current = false;
      setVisible(false);
      deffered.current?.rejecter(
        GetError(
          new Error((error as any).response.data.message),
          "enableSensor",
          "UseWallet"
        )
      );
    }
  }, [_enableSensor, addLog]);

  const open = useCallback(
    (_order: IOrder) => {
      enableSensor();
      setOrder(_order);
      setVisible(true);
      enabled.current = true;
      deffered.current = createDefferedPromise<IWalletReturn>();
      return deffered.current.promise;
    },
    [enableSensor]
  );

  useImperativeHandle(ref, () => ({ open }));

  const onKeyPress = useCallback(
    (key: string) => {
      if (password.length < 4) {
        setPassword(password + key);
      }
    },
    [password]
  );
  const onClearPress = useCallback(() => {
    setPassword("");
  }, []);

  const onRemovePress = useCallback(() => {
    setPassword((prev) => prev.substring(0, prev.length - 1));
  }, []);

  const onCancel = useCallback(() => {
    setVisible(false);
    disableSensor();
    enabled.current = false;
    deffered.current?.rejecter(
      new Error("Fechado pelo usuário ou tempo esgotado")
    );
  }, [disableSensor]);

  const walletPayment = useCallback(async () => {
    if (walletTag.current && kiosk?.localId) {
      try {
        addLog("CashlessStore", {
          message: "Wallet Payment",
          payload: {
            orderId: order.id,
            tag: walletTag.current,
            value: order.totalValue,
          },
        });
        showLoading();
        const walletService = WalletRepository();
        const walletTransaction = await WalletPaymentUseCase(
          walletService,
          kiosk.localId,
          order.id,
          walletTag.current,
          password,
          order.totalValue,
          v4()
        );

        const walletSyncTransaction: IWalletSyncTransaction = {
          log: {
            balance: walletTransaction.balance,
            walletId: walletTransaction.walletId,
            orderId: walletTransaction.orderId,
            preOrder: {
              preOrderId: walletTransaction.preOrder.preOrderId,
              totalValue: walletTransaction.preOrder.totalValue,
            },
            tag: walletTransaction.tag,
            meepUser: {
              cpf: walletTransaction.meepUser.cpf,
              name: walletTransaction.meepUser.name,
              userId: walletTransaction.meepUser.userId,
            },
          },
        };

        addLog("CashlessStore", {
          message: "Pagamento confirmado",
          walletTransaction,
          order,
        });
        setVisible(false);
        disableSensor();
        deffered.current.resolver({
          order,
          walletTransaction: walletSyncTransaction,
        });
      } catch (error) {
        if ((error as any)?.response?.data?.message.includes("bloqueado")) {
          addLog("CashlessStore", { message: "O cartão está bloqueado", order });
          setVisible(false);
          deffered.current.rejecter(new Error("O cartão está bloqueado"));
        } else if ((error as any).message.includes("Senha incorreta")) {
          addLog("CashlessStore", { message: "Senha incorreta", order });
          setPassword("");
          setShowPasswordPicker(true);
        } else {
          addLog("CashlessStore", {
            message: (error as any).message,
            order,
            error,
          });
          setVisible(false);
          deffered.current.rejecter(error);
        }
      } finally {
        hideLoading();
      }
    } else {
      toast("Tag não encontrada", "error");
    }
  }, [
    addLog,
    disableSensor,
    hideLoading,
    kiosk,
    order,
    password,
    showLoading,
    toast,
  ]);

  const onConfirm = useCallback(() => {
    if (password.length !== 4) {
      toast("Senha deve conter 4 dígitos", "error");
    } else {
      setShowPasswordPicker(false);
      walletPayment();
    }
  }, [password.length, toast, walletPayment]);

  return (
    <Modal open={visible} id={styles.Wallet}>
      <div className={styles.cashlessModal}>
        <div className={styles.container}>
          <div className={styles.topLabel}>CONFIRMAR PEDIDO</div>
          <IconButton onClick={onCancel} className={styles.closeIcon}>
            <Icon fontSize="large">close</Icon>
          </IconButton>
        </div>
        {!showPasswordPicker && (
          <div className={styles.content}>
            <Icon fontSize="large">nfc</Icon>
            <div>Aproxime seu cartão/pulseira do leitor</div>
          </div>
        )}
        <Drawer
          anchor="bottom"
          open={showPasswordPicker}
          onClose={() => setShowPasswordPicker(false)}
        >
          <div className={"drawer-paymentCashless"}>
            <h2>Digite sua senha</h2>
            <div className={"password-paymentCashless"}>
              {password.replace(/[0-9]/g, "*")}
            </div>
            <TecladoVirtual
              onKeyPress={onKeyPress}
              onPressClear={onClearPress}
              onPressBack={onRemovePress}
              type="numeric"
              onPressEnter={onConfirm}
            />
          </div>
        </Drawer>
      </div>
    </Modal>
  );
};

export default forwardRef(UseWallet);
