import { Modal } from "@material-ui/core";
import { InputCpfCnpj } from "areas/payment/inputCpfCnpj/InputCpfCnpj";
import { InputField } from "areas/payment/inputField/InputField";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { ICustomer } from "modules/order/domain/models/ICustomer";
import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  ICreateDefferedPromise,
  createDefferedPromise,
} from "utils/DefferedPromise";
import styles from "./CustomerIdentification.module.scss";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";

type IKeyboardType = "numeric" | "alphanumeric" | "email";
type IInputType = "CPF" | "PHONE";

interface IIdentificationField {
  name: string;
  required?: boolean;
}

interface IIdentificationFields {
  [x: string]: IIdentificationField;
}

export interface ICustomerIdentificationHandler {
  open(kiosk: Kiosk, recharge?: "Recharge" | "Payment" | "WalletRecharge"): Promise<ICustomer>;
}

interface ICustomerIdentificationProps {}

const CustomerIdentification: React.ForwardRefRenderFunction<
  ICustomerIdentificationHandler,
  ICustomerIdentificationProps
> = ({}, ref) => {
  const deffered = useRef<ICreateDefferedPromise<ICustomer>>(
    createDefferedPromise()
  );

  const { addLog } = useAppInsights();

  const [visible, setVisible] = useState(false);
  const [customerFields, setCustomerFields] = useState<IIdentificationFields>(
    {}
  );
  const customer = useRef<ICustomer>({});
  const [currentStep, setCurrentStep] = useState(0);

  const totalFields = Object.keys(customerFields).length;

  const getCustomerFields = useCallback((kiosk: Kiosk, recharge?: "Recharge" | "Payment" | "WalletRecharge") => {
    let fields: IIdentificationFields = {};

    // Campos identificação do cliente
    kiosk.customerField.forEach((it) => {
      const required = it.endsWith("*");
      const name = required ? it.slice(0, -1) : it;

      if (name.length) {
        fields[name] = {
          name,
          required,
        };
      }
    });

    if (kiosk.generateEletronicInvoice) {
      if (
        kiosk.generateEletronicInvoiceForRecharge ||
        (!kiosk.generateEletronicInvoiceForRecharge && (!recharge || recharge === "Payment"))
      ) {
        fields["informCPF"] = {
          name: "informCPF",
        };
      }
    }

    if (kiosk.prism && !recharge) {
      fields["prism"] = {
        name: "prism",
        required: true,
      };
    }

    if (Object.keys(fields).length === 0) {
      setVisible(false);
      deffered.current.resolver({} as ICustomer);
    }

    setCustomerFields(fields);
  }, []);

  const open = useCallback(
    (kiosk: Kiosk, recharge?: "Recharge" | "Payment" | "WalletRecharge") => {
      deffered.current = createDefferedPromise<ICustomer>();
      setVisible(true);

      getCustomerFields(kiosk, recharge);
      return deffered.current.promise;
    },
    [getCustomerFields]
  );

  useImperativeHandle(ref, () => ({ open }));

  const getKeyboardType = useCallback(
    (field: IIdentificationField): IKeyboardType => {
      if (field.name.includes("email")) {
        return "email";
      }
      if (field.name.includes("cpf") || field.name.includes("telefone")) {
        return "numeric";
      } else {
        return "alphanumeric";
      }
    },
    []
  );

  const getInputType = useCallback(
    (field: IIdentificationField): IInputType | undefined => {
      if (field.name.includes("telefone")) {
        return "PHONE";
      }
      if (field.name.includes("cpf")) {
        return "CPF";
      }
    },
    []
  );

  const handleStep = useCallback(() => {
    if (currentStep < totalFields) {
      const fieldName = Object.keys(customerFields)[currentStep];
      const field = customerFields[fieldName];

      const askInformNfce = field.name.includes("informCPF");

      const onClickNext = () => {
        setCurrentStep((prev) => {
          if (totalFields - prev === 1) {
            addLog("customerFields", {
              ...Object.fromEntries(
                Object.entries(customer.current).map(([key, value]) => [
                  key,
                  !!value,
                ])
              ),
            });
            setVisible(false);
            setCustomerFields({});
            const customerFields = customer.current;
            customer.current = {};
            setCurrentStep(0);
            deffered.current.resolver(customerFields);
            return totalFields;
          } else {
            return prev + 1;
          }
        });
      };

      const handleSaveData = (value?: string) => {
        if (field.name.includes("nome")) {
          customer.current = {
            ...customer.current,
            name: value,
          };
        }
        if (field.name.includes("telefone")) {
          customer.current = {
            ...customer.current,
            phone: value,
          };
        }
        if (field.name.includes("cpf")) {
          customer.current = {
            ...customer.current,
            document: value,
          };
        }
        if (field.name.includes("email")) {
          customer.current = {
            ...customer.current,
            email: value,
          };
        }
        if (field.name.includes("prism")) {
          customer.current = {
            ...customer.current,
            prism: value,
          };
        }
        if (field.name.includes("identificador")) {
          customer.current = {
            ...customer.current,
            clientIdentificator: value,
          };
        }

        onClickNext();
      };

      if (askInformNfce) {
        const saveDocument = (document?: string) => {
          customer.current = {
            ...customer.current,
            documentToNf: document,
            informCPF: true,
          };
          onClickNext();
        };
        return (
          <div className={styles.nfceContainer}>
            <InputCpfCnpj
              onPressContinuar={saveDocument}
              value={customer.current.documentToNf ?? undefined}
            />
          </div>
        );
      } else {
        return (
          <InputField
            description={
              field.name.includes("prism")
                ? `Digite sua mesa`
                : `Digite seu ${field.name.toUpperCase()}`
            }
            keyboardType={getKeyboardType(field)}
            type={getInputType(field)}
            required={field.required}
            minInputLength={
              field.name.includes("cpf")
                ? 11
                : field.name.includes("prism")
                ? 1
                : 3
            }
            maxInputLength={field.name.includes("cpf") ? 11 : undefined}
            onPressContinuar={handleSaveData}
            onPressCancel={handleSaveData}
          />
        );
      }
    }
  }, [
    currentStep,
    customer,
    customerFields,
    getInputType,
    getKeyboardType,
    totalFields,
    addLog,
  ]);

  return (
    <Modal open={visible} id={styles.customerIdentificationContainer}>
      <div className={styles.inputContainer}>{handleStep()}</div>
    </Modal>
  );
};
export default React.forwardRef(CustomerIdentification);
