import { ErrorCodeType } from "application/models/IError";
import { IEmitedNfce, INfce } from "modules/order/domain/models/INfce";
import { IOrder } from "modules/order/domain/models/IOrder";
import { GetError } from "utils/GetError";
import { INfceService } from "../interfaces/INfceService";

export const EmitNfceUseCase = async (
  service: INfceService,
  order: IOrder,
): Promise<IEmitedNfce[]> => {
  try {
    const nfce = assembleNfce(order)
    const result = await service.emitNfce(nfce);
    return result;
  } catch (error) {
    return Promise.reject(
      GetError(
        error,
        "EmitNfceUseCase",
        "EmitNfceUseCase",
        "Falha ao emitir Nfce",
        ErrorCodeType.NFCE
      )
    );
  }
  // __DEV__ && console.log(result);
};

// Mudar de arquivo
const assembleNfce = (
  order: IOrder,
): INfce => {
  console.log("NFE ORDER", order);

  const eletronicInvoice: INfce = {
    orderId: order.id,
    document: order.customer?.documentToNf,
    isManual: false
  };

  console.log("NFE", eletronicInvoice);
  return eletronicInvoice;
};
