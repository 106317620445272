import { IOrderRepository } from "../../../domains/order/interfaces/repositories/IOrderRepository";
import { IHttpRepository } from "../../interfaces/IHttpRepository";
import { v4 } from "uuid";
import { TransactionType } from "../../../domains/order/agreggates/payment/transactionType";
import moment from "moment";
import { ICefSharpOrderService } from "../../services/cefSharpService/local/Interfaces/ICefSharpOrderService";
import { ILocalStorageRepository } from "../../interfaces/ILocalStorageRepository";
import { OrderCompositionItem } from "../../../domains/order/agreggates/order/OrderCompositionItem";
import { OrderComposition } from "../../../domains/order/agreggates/order/OrderComposition";
import { Order } from "../../../domains/order/agreggates/order/Order";
import { FinancialTransactionModel } from "../../../domains/order/agreggates/payment/FinancialTransactionModel";
import {
  OpcaoDoConsumidor,
  OpcoesDoConsumidor,
  PedidoPos,
  ProdutoPedidoPos,
  DadosPedidosPOS,
  responseSyncPedidoPos,
  ClienteEstabelecimento,
  StatusPedido,
  DadosConsumoPOS,
  ConsumoPedidoPos,
  ConsumoItemPedido,
  ConsumoAdicionais,
  ConsumoObservacoes,
  ProdutoTipo,
} from "./PedidoMeep";
import { HttpRepository } from "./_HttpRepository";
import { CefSharpLocalOrderRepository } from "../../services/cefSharpService/local/CefSharpLocalOrderService";
import { LocalStorageRepository } from "./_LocalStorageRepository";
import { CashlessRepository } from "./CashlessRepository";
import { OrderRepositoryLocalStorage } from "../localStorage/OrderRepositoryLocalStorage";
import { IPasswordPainel } from "./PasswordPainel";
import { IWalletRechargeModel } from "modules/payment/domain/models/IWalletTransaction";

const httpRepository: IHttpRepository = HttpRepository();
const cefSharpOrderService: ICefSharpOrderService =
  CefSharpLocalOrderRepository();
const localStorageRepository: ILocalStorageRepository =
  LocalStorageRepository();
const cashlessRepository = CashlessRepository();
const orderRepository = OrderRepositoryLocalStorage();

export const MeepOrderRepository = (
  addLog?: (log: string, data: any) => void
): IOrderRepository => {
  const sendOrderAsync = async (
    order: Order,
    tipoPagamento: TransactionType,
    transacaoAdquirente?: FinancialTransactionModel
  ) => {
    const pedidoPos = orderToPedidoPOS(
      order,
      tipoPagamento,
      transacaoAdquirente
    );
    await saveStorageOrder([pedidoPos]);
    if (pedidoPos.isOfflineRecharge) {
      await savePendentConfirmationRecordOfflineOrders([pedidoPos]);
    }
    return pedidoPos;
  };

  const sendSchoolOrderAsync = async (order: Order, localClientId: string) => {
    const pedidoPos = orderToPedidoPOS(order, TransactionType.cashless);
    const dadosPedidoPOS: DadosPedidosPOS = {
      localClienteId: localClientId,
      tipoDispositivo: 2,
      sincronizarPedidoPOSInsertModel: JSON.stringify([pedidoPos]),
    };

    addLog && addLog("Request", pedidoPosToLog([pedidoPos]));

    const response = await httpRepository.postAsync<
      DadosPedidosPOS,
      responseSyncPedidoPos[]
    >("/Pagamento/SincronizarPedidoPOS", dadosPedidoPOS);
    // >("https://0f93-186-248-214-220.ngrok-free.app/Promptus.Meep.Server/api/Pagamento/SincronizarPedidoPOS", dadosPedidoPOS);

    if (response.length && response[0].pedidoPOSId === pedidoPos.pedidoPOSId) {
      return pedidoPos;
    }
  };

  const SincronizarPedidoPOSAsync = async (
    dadosPedidosPOS: DadosPedidosPOS
  ) => {
    addLog &&
      addLog(
        "Request",
        pedidoPosToLog(
          JSON.parse(dadosPedidosPOS.sincronizarPedidoPOSInsertModel) ?? []
        )
      );
    let response = await httpRepository.postAsync<
      DadosPedidosPOS, responseSyncPedidoPos[]
    >("/Pagamento/SincronizarPedidoPOS", dadosPedidosPOS);
    // >("https://0f93-186-248-214-220.ngrok-free.app/Promptus.Meep.Server/api/Pagamento/SincronizarPedidoPOS", dadosPedidosPOS);
    Promise.all(
      response.map((it, key) =>
        setTimeout(() => {
          orderRepository.add(
            { id: it.pedidoPOSId, isSynchronized: true } as Order,
            "Sincronizado com sucesso!"
          );
        }, key * 500)
      )
    );
    return response;
  };

  const savePendentConfirmationRecordOfflineOrders = async (
    pedidosPOS: PedidoPos[]
  ) => {
    const storagePedidosPOS: PedidoPos[] =
      (await localStorageRepository.get<PedidoPos[]>("PendentRecordConfirmation")) ?? [];

    const newPendentOrder = [...storagePedidosPOS, ...pedidosPOS];
    localStorageRepository.post("PendentRecordConfirmation", newPendentOrder);
  };

  const savePendentConfirmationWalletRechargeOrders = async (
    pedidosRecargaWallet: IWalletRechargeModel[]
  ) => {
    const storagePedidosWallet: IWalletRechargeModel[] =
      (await localStorageRepository.get<IWalletRechargeModel[]>("PendentWalletRecharge")) ?? [];

    const newPendentOrder = [...storagePedidosWallet, ...pedidosRecargaWallet];
    localStorageRepository.post("PendentWalletRecharge", newPendentOrder);
  };

  const saveStorageOrder = async (pedidosPOS: PedidoPos[]) => {
    const storagePedidosPOS: PedidoPos[] = (await localStorageRepository.get<PedidoPos[]>("PendentOrders")) ?? [];
    localStorageRepository.post("PendentOrders", [
      ...storagePedidosPOS,
      ...pedidosPOS,
    ]);
  };

  const getStoragePendentOrder = async (): Promise<PedidoPos[]> => {
    const storagePedidosPOS: PedidoPos[] = (await localStorageRepository.get<PedidoPos[]>("PendentOrders")) ?? [];
    return storagePedidosPOS;
  };

  const getPendentWalletRecharge = async (): Promise<IWalletRechargeModel[]> => {
    const storagePedidosPOS: IWalletRechargeModel[] = (await localStorageRepository.get<IWalletRechargeModel[]>("PendentWalletRecharge")) ?? [];
    return storagePedidosPOS;
  };

  const getStoragePendentRecordConfirmationOrder = async (): Promise<
    PedidoPos[]
  > => {
    const storagePedidosPOS: PedidoPos[] = (await localStorageRepository.get<PedidoPos[]>("PendentRecordConfirmation"))
      ?.filter((it) => it.isDataRecorded && !it.isOfflineRechargeRecordConfirmed) ?? [];
    return storagePedidosPOS;
  };

  const getPasswordPainel = async (
    sessaoId: string,
    localId: string
  ): Promise<IPasswordPainel> => {
    return await httpRepository.getAsync<IPasswordPainel>(`PasswordPanel/NextOrderPasssoword/LocalClient/${localId}/Cashier/${sessaoId}`);
  };

  const removeSendedOrderFromStorageQueueAsync = async (
    responseIds: responseSyncPedidoPos[]
  ) => {
    const storagePedidosPos = await localStorageRepository.get<PedidoPos[]>("PendentOrders");
    const newPendentStoragePedidosPos: PedidoPos[] = [];
    storagePedidosPos?.map((storagePedido) => {
      if (
        responseIds.findIndex(
          (responseIds) => responseIds.pedidoPOSId === storagePedido.pedidoPOSId
        ) === -1
      ) {
        newPendentStoragePedidosPos.push(storagePedido);
      }
    });
    await localStorageRepository.remove("PendentOrders");
    await saveStorageOrder(newPendentStoragePedidosPos);
  };

  const removeRecordedConfirmationOrderFromStorageQueueAsync = async (
    responseIds: responseSyncPedidoPos[]
  ) => {
    const storagePedidosPos = await localStorageRepository.get<PedidoPos[]>("PendentRecordConfirmation");
    const newPendentStoragePedidosPos: PedidoPos[] = [];
    storagePedidosPos?.map((storagePedido) => {
      if (
        responseIds.findIndex(
          (responseIds) => responseIds.pedidoPOSId === storagePedido.pedidoPOSId
        ) === -1
      ) {
        newPendentStoragePedidosPos.push(storagePedido);
      } else {
        orderRepository.add(
          {
            id: storagePedido.pedidoPOSId,
            isOfflineRechargeRecordConfirmed: true,
          } as Order,
          "Confirmação da gravação de recarga"
        );
      }
    });
    await localStorageRepository.remove("PendentRecordConfirmation");
    await savePendentConfirmationRecordOfflineOrders(
      newPendentStoragePedidosPos
    );
  };

  const removeWalletPendingRechargeFromStorageQueueAsync = async (
    id: string
  ) => {
    const storageWalletRecharges = await localStorageRepository.get<IWalletRechargeModel[]>("PendentWalletRecharge");
    const newRechargeWalletList = storageWalletRecharges?.filter(it => it.orderId !== id) ?? [];
    await localStorageRepository.post("PendentWalletRecharge", newRechargeWalletList)
    return newRechargeWalletList;
  };

  const syncOrdersAsync = async (localClienteId: string) => {
    const storagePedidosPOS = await localStorageRepository.get<PedidoPos[]>("PendentOrders");
    const storagePedidosPOSConfirmationRecord = await localStorageRepository.get<PedidoPos[]>("PendentRecordConfirmation");

    let responseSync: responseSyncPedidoPos[] = [];

    if (storagePedidosPOS && storagePedidosPOS?.length > 0) {
      // Caso pagamento seja pix, será sincronizado como debito
      const pedidoPosParaSincronizar = storagePedidosPOS.map((it) => ({
        ...it,
        tipoPagamento:
          it.tipoPagamento === TransactionType.pix
            ? TransactionType.debit
            : it.tipoPagamento,
      }));

      const dadosPedidoPOS: DadosPedidosPOS = {
        localClienteId: localClienteId,
        tipoDispositivo: 2,
        sincronizarPedidoPOSInsertModel: JSON.stringify(
          pedidoPosParaSincronizar ?? "[]"
        ),
      };
      addLog && addLog("Request", pedidoPosToLog(pedidoPosParaSincronizar));
      const result = await SincronizarPedidoPOSAsync(dadosPedidoPOS);
      if (result) {
        responseSync = [...responseSync, ...result];
        removeSendedOrderFromStorageQueueAsync(result);
      }
    }

    if (
      storagePedidosPOSConfirmationRecord &&
      storagePedidosPOSConfirmationRecord?.length > 0
    ) {

      // Caso pagamento seja pix, será sincronizado como debito
      const pedidosConfirmacaoPendente =
        storagePedidosPOSConfirmationRecord.map((it) => ({
          ...it,
          tipoPagamento:
            it.tipoPagamento === TransactionType.pix
              ? TransactionType.debit
              : it.tipoPagamento,
        }));

      const pedidoPosParaConfirmarGravacao = pedidosConfirmacaoPendente.filter(
        (it) => it.isDataRecorded && !it.isOfflineRechargeRecordConfirmed
      );

      if (pedidoPosParaConfirmarGravacao.length) {
        const ordersIdToConfirmRecord = pedidoPosParaConfirmarGravacao
          .map((it) => it.pedidoPOSId || "")
          .filter(
            (id) => !storagePedidosPOS?.map((i) => i.pedidoPOSId).includes(id)
          );

        addLog &&
          addLog("Request", pedidoPosToLog(pedidoPosParaConfirmarGravacao));
        const result = await cashlessRepository.confirmPendingRecord(
          ordersIdToConfirmRecord
        );
        if (result) {
          responseSync = [
            ...responseSync,
            ...result.ordersId.map((it) => ({ pedidoPOSId: it })),
          ];
          removeRecordedConfirmationOrderFromStorageQueueAsync(
            result.ordersId.map((it) => ({ pedidoPOSId: it }))
          );
        }
      }
    }

    return responseSync;
  };

  const markAsRecorded = async (orderId: string) => {
    let storagePedidosPOSConfirmationRecord = await localStorageRepository.get<
      PedidoPos[]
    >("PendentRecordConfirmation");

    if (
      storagePedidosPOSConfirmationRecord &&
      storagePedidosPOSConfirmationRecord?.length > 0
    ) {
      const index = storagePedidosPOSConfirmationRecord.findIndex(
        (item) => item.pedidoPOSId === orderId
      );
      if (index >= 0) {
        storagePedidosPOSConfirmationRecord[index].isDataRecorded = true;

        try {
          await localStorageRepository.remove("PendentRecordConfirmation");
          await savePendentConfirmationRecordOfflineOrders(
            storagePedidosPOSConfirmationRecord
          );
        } catch (error) {
          addLog &&
            addLog("error", error);
        }
      }
    }
  };

  const syncLastOrdersAsync = async (localClienteId: string) => {
    const storagePedidosPOS = await localStorageRepository.get<PedidoPos[]>(
      "PendentOrders"
    );
    if (storagePedidosPOS && storagePedidosPOS?.length > 0) {
      // Caso pagamento seja pix, será sincronizado como debito
      const pedidoPosParaSincronizar = {
        ...storagePedidosPOS[0],
        tipoPagamento:
          storagePedidosPOS[0].tipoPagamento === TransactionType.pix
            ? TransactionType.debit
            : storagePedidosPOS[0].tipoPagamento,
      };

      const dadosPedidoPOS: DadosPedidosPOS = {
        localClienteId: localClienteId,
        tipoDispositivo: 2,
        sincronizarPedidoPOSInsertModel: JSON.stringify(
          [pedidoPosParaSincronizar]
        ),
      };
      addLog && addLog("Request", pedidoPosToLog([pedidoPosParaSincronizar]));
      const responseSync = await SincronizarPedidoPOSAsync(dadosPedidoPOS);
      if (responseSync) {
        removeSendedOrderFromStorageQueueAsync(responseSync);
      }
      return responseSync;
    } else {
      return [];
    }
  };

  const syncOrdersByIdAsync = async (
    localClienteId: string,
    orderId: string
  ) => {
    const storagePedidosPOS = await localStorageRepository.get<PedidoPos[]>(
      "PendentOrders"
    );
    const storagePedidosPOSConfirmationRecord =
      await localStorageRepository.get<PedidoPos[]>(
        "PendentRecordConfirmation"
      );

    let responseSync: responseSyncPedidoPos[] = [];

    if (storagePedidosPOS && storagePedidosPOS?.length > 0) {
      // Caso pagamento seja pix, será sincronizado como debito
      const pedidoPos = storagePedidosPOS.filter(
        (item) => item.pedidoPOSId === orderId
      );
      const pedidoPosParaSincronizar = pedidoPos.map((it) => ({
        ...it,
        tipoPagamento:
          it.tipoPagamento === TransactionType.pix
            ? TransactionType.debit
            : it.tipoPagamento,
      }));

      const dadosPedidoPOS: DadosPedidosPOS = {
        localClienteId: localClienteId,
        tipoDispositivo: 2,
        sincronizarPedidoPOSInsertModel: JSON.stringify(
          pedidoPosParaSincronizar ?? "[]"
        ),
      };
      addLog && addLog("Request", pedidoPosToLog(pedidoPosParaSincronizar));
      const result = await SincronizarPedidoPOSAsync(dadosPedidoPOS);
      if (result) {
        responseSync = [...responseSync, ...result];
        removeSendedOrderFromStorageQueueAsync(result);
      }
    }

    if (
      storagePedidosPOSConfirmationRecord &&
      storagePedidosPOSConfirmationRecord?.length > 0
    ) {
      // Caso pagamento seja pix, será sincronizado como debito
      const pedidoPos = storagePedidosPOSConfirmationRecord.filter(
        (item) => item.pedidoPOSId === orderId
      );
      const pedidoPosParaSincronizar = pedidoPos.map((it) => ({
        ...it,
        tipoPagamento:
          it.tipoPagamento === TransactionType.pix
            ? TransactionType.debit
            : it.tipoPagamento,
      }));

      const dadosPedidoPOS: DadosPedidosPOS = {
        localClienteId: localClienteId,
        tipoDispositivo: 2,
        sincronizarPedidoPOSInsertModel: JSON.stringify(
          pedidoPosParaSincronizar ?? "[]"
        ),
      };
      addLog && addLog("Request", pedidoPosToLog(pedidoPosParaSincronizar));
      const result = await SincronizarPedidoPOSAsync(dadosPedidoPOS);
      if (result) {
        responseSync = [...responseSync, ...result];
        removeSendedOrderFromStorageQueueAsync(result);
      }
    }
    return responseSync;
  };

  const sendNowOrdersAsync = async (
    order: Order,
    localClientId: string,
    tipoPagamento?: TransactionType,
    transacaoAdquirente?: FinancialTransactionModel,
    equipamentId?: string
  ): Promise<PedidoPos> => {
    const pedidoPos: PedidoPos = orderToPedidoPOS(
      order,
      tipoPagamento,
      transacaoAdquirente
    );

    // Caso pagamento seja pix, será sincronizado como debito
    const pedidoPosParaSincronizar = {
      ...pedidoPos,
      tipoPagamento:
        tipoPagamento === TransactionType.pix
          ? TransactionType.debit
          : tipoPagamento,
    };

    const dadosPedidoPOS: DadosPedidosPOS = {
      localClienteId: localClientId,
      tipoDispositivo: 2,
      sincronizarPedidoPOSInsertModel: JSON.stringify(
        [pedidoPosParaSincronizar]
      ),
    };

    let responseSync: responseSyncPedidoPos[] = [];

    if (tipoPagamento === TransactionType.pospaid) {
      const consumeOrder = orderToConsusmeOrder(
        pedidoPos,
        localClientId,
        equipamentId
      );

      addLog && addLog("Request", consumeOrderToLog(consumeOrder));

      const result = await cashlessRepository.doConsumption(consumeOrder);
      responseSync = [
        {
          pedidoPOSId: result.orderId,
        },
      ];
    } else {
      responseSync = await SincronizarPedidoPOSAsync(dadosPedidoPOS);
    }

    if (responseSync.find((res) => res.pedidoPOSId === order.id)) {
      return pedidoPos;
    } else {
      throw "Pedido Não enviado";
    }
  };

  const saveInFileAsync = async (
    id: string,
    request: string,
    response: string
  ): Promise<boolean> => {
    return cefSharpOrderService.saveInFileAsync(id, request, response);
  };

  return {
    saveInFileAsync,
    sendOrderAsync,
    syncOrdersAsync,
    sendNowOrdersAsync,
    getStoragePendentOrder,
    getStoragePendentRecordConfirmationOrder,
    orderToPedidoPOS,
    syncLastOrdersAsync,
    syncOrdersByIdAsync,
    getPasswordPainel,
    sendSchoolOrderAsync,
    markAsRecorded,
    savePendentConfirmationWalletRechargeOrders,
    getPendentWalletRecharge,
    removeWalletPendingRechargeFromStorageQueueAsync
  };
};

export const orderCompositionItensToOpcoes = (
  orderCompositionItens: OrderCompositionItem[]
) => {
  const newOpcoes = orderCompositionItens.map<OpcaoDoConsumidor>(
    (orderCompositionItem) => ({
      id: orderCompositionItem.compositionItemId ?? v4(),
      produtoId: orderCompositionItem.productId,
      descricao: orderCompositionItem.description,
      isChecked: orderCompositionItem.quantity > 0,
      quantidade: orderCompositionItem.quantity,
      quantidadeGratuitoSelecionado: orderCompositionItem.quantityFreeSelected,
      produtoValor: orderCompositionItem.price,
      quantidadeGratuito: orderCompositionItem.quantityFree,
      isProductGeneralObservation: orderCompositionItem.isProductGeneralObservation,
    })
  );

  return newOpcoes;
};

export const orderCompositionToOpcoesDoConsumidor = (
  orderComposition: OrderComposition[]
) => {
  const newOpcoesDoConsumidor: OpcoesDoConsumidor[] =
    orderComposition.map<OpcoesDoConsumidor>((orderComposition) => {
      const newOpcoes = orderCompositionItensToOpcoes(
        orderComposition.orderCompositionItems
      );
      return {
        id: orderComposition.compositionId,
        descricao: orderComposition.description,
        opcoes: newOpcoes,
      };
    });
  return newOpcoesDoConsumidor;
};

export const orderToPedidoPOS = (
  order: Order,
  tipoPagamento?: TransactionType,
  transacaoAdquirente?: FinancialTransactionModel
): PedidoPos => {
  const newProdutos: ProdutoPedidoPos[] = [];

  order.orderItems.map((orderItem) => {
    const constNewOpcoesConsumidor = orderCompositionToOpcoesDoConsumidor(
      orderItem.orderComposition ?? []
    );
    const isProductGranel = orderItem.isWeight;
    if (!isProductGranel) {
      for (let index = 0; index < orderItem.quantity; index++) {
        newProdutos.push({
          nome: orderItem.name ?? "",
          nomeProduto: orderItem.name ?? "",
          ticketId: v4(),
          tipoProduto: orderItem.productType,
          produtoId: orderItem.productId,
          valor: orderItem.productPrice, //total product price para Kg e real price pro resto
          opcoesDoConsumidor: JSON.stringify(constNewOpcoesConsumidor),
          opcoesDoConsumidorObject: constNewOpcoesConsumidor,
          category: orderItem?.category,
          storeName: orderItem.storeName,
        });
      }
    } else {
      newProdutos.push({
        nome: orderItem.name ?? "",
        nomeProduto: orderItem.name ?? "",
        ticketId: v4(),
        tipoProduto: orderItem.productType,
        produtoId: orderItem.productId,
        valor: orderItem.realPrice,
        opcoesDoConsumidor: JSON.stringify(constNewOpcoesConsumidor),
        opcoesDoConsumidorObject: constNewOpcoesConsumidor,
        category: orderItem?.category,
        quantidade: orderItem.quantity,
        storeName: orderItem.storeName,
      });
    }
  });

  const clienteEstabelecimento: ClienteEstabelecimento = {
    nome: order.customer?.name,
    identificador: order?.customer?.clientIdentificator,
    cpf: order.customer?.document,
    telefone: order.customer?.phone,
    email: order.customer?.email,
    comanda: order.customer?.orderPad,
    mesa: order.customer?.prism,
    pedidoPosPaiId: order.customer?.associationId,
    pedidoPosId: order.customer?.orderId,
    tag: order.customer?.tag,
  };

  const dadosTransacao =
    tipoPagamento === TransactionType.pix && order?.paymentPix
      ? JSON.stringify(order.paymentPix)
      : tipoPagamento === TransactionType.wallet && order?.paymentWallet 
      ? JSON.stringify(order.paymentWallet)
      : transacaoAdquirente
      ? JSON.stringify(transacaoAdquirente)
      : "";

  const newPedidoPos: PedidoPos = {
    codigoPedido: order.friendlyId,
    tipoPagamento: tipoPagamento, //Enum tipo de pagamento 1=credito 2=debito
    pedidoPOSId: order.id, //Guid Gerado no totem
    statusPedido:
      transacaoAdquirente?.Status === StatusPedido.AguardandoPagamento
        ? StatusPedido.AguardandoPagamento
        : tipoPagamento === TransactionType.pospaid
        ? StatusPedido.Pendente
        : StatusPedido.Aprovado, // 3 pagamento realizado
    tipoAdquirente:
      transacaoAdquirente?.TipoAdquirente &&
      transacaoAdquirente?.TipoAdquirente !== 1
        ? transacaoAdquirente.TipoAdquirente
        : 6, //6=cielo
    dataPedido: moment.utc().toDate(), //UTC
    dadosTransacao,
    dadosTransacaoObject: transacaoAdquirente,
    produtos: JSON.stringify(newProdutos),
    produtosObject: newProdutos,
    valorTotal: order.totalValue,
    pedidoPOSPaiId: order.customer?.associationId,
    tagCashless: order.customer?.tag ?? "",
    clienteEstabelecimento: JSON.stringify(clienteEstabelecimento),
    clienteEstabelecimentoObject: clienteEstabelecimento,
    observacaoPedido: order.observation,
    codigoPainelSenha: order.passwordPanelCode,
    isDataRecorded: "isDataRecorded" in order ? order.isDataRecorded : null,
    isOfflineRecharge: order?.isOfflineRecharge,
    isOfflineRechargeRecordConfirmed: order?.isOfflineRechargeRecordConfirmed,
  };

  return newPedidoPos;
};

export const orderToConsusmeOrder = (
  pedidoPos: PedidoPos,
  localId: string,
  equipamentId?: string
): DadosConsumoPOS => {
  let consumoPos: DadosConsumoPOS;
  let items: ConsumoItemPedido[] = [];
  let annotation: string = "";

  pedidoPos.produtosObject.forEach((it) => {
    let additionals: ConsumoAdicionais[] = [];
    let observations: ConsumoObservacoes[] = [];

    it.opcoesDoConsumidorObject.forEach((op) => {
      const isAdditional = op.opcoes.every((o) => o.produtoId);
      if (isAdditional) {
        op.opcoes.forEach((opcao) => {
          additionals.push({
            productId: opcao.produtoId ?? opcao.id,
            selectedQuantity: opcao.quantidade,
            unitValue: opcao.produtoValor,
            discountValue: opcao.quantidadeGratuito * opcao.produtoValor,
          });
        });
      } else {
        op.opcoes.forEach((opcao) => {
          const obs = {
            id: opcao.id,
            question: op.descricao,
            answer: opcao.descricao,
          };
          if (obs.answer && !opcao.isProductGeneralObservation) {
            observations.push(obs);
          } else if (opcao.isProductGeneralObservation) {
            annotation = obs.answer;
          }
        });
      }
    });

    const totalValue =
      it.valor +
      additionals.reduce((acc, cur) => {
        const curValue =
          cur.unitValue * cur.selectedQuantity - cur.discountValue;
        acc += curValue;
        return acc;
      }, 0);

    const item = {
      ticketsIds: [it.ticketId],
      productId: it.produtoId,
      productName: it.nomeProduto,
      unitValue: totalValue,
      discountValue: 0,
      quantity: it.quantidade ?? 1,
      annotation,
      additionals,
      observations,
      optionsOldFormat: "",
    };

    const index = items.findIndex((i) => {
      const findString =
        (i.productId ?? "") +
        i.unitValue +
        JSON.stringify(i.additionals) +
        JSON.stringify(i.observations);
      const itemString =
        (it.produtoId ?? "") +
        it.valor +
        JSON.stringify(additionals) +
        JSON.stringify(observations);
      return findString === itemString;
    });

    if ((index >= 0 || items[index]) && it.tipoProduto !== ProdutoTipo.Kilo) {
      items[index].quantity += 1;
      items[index].ticketsIds.push(it.ticketId);
    } else {
      //Não sincronizar taxa de serviço no consumo pós pago
      if (!item.productName.includes("Taxa de serviço")) {
        items.push(item);
      }
    }
  });

  let _order: ConsumoPedidoPos = {
    orderId: pedidoPos.pedidoPOSId,
    createdAt: pedidoPos.dataPedido,
    descriptionPersonalizedDelivery:
      pedidoPos.clienteEstabelecimentoObject.mesa,
    items,
  };

  consumoPos = {
    localId: localId,
    accountId: pedidoPos.pedidoPOSPaiId ?? "",
    equipamentId: equipamentId ?? "",
    order: _order,
  };

  return consumoPos;
};

const pedidoPosToLog = (order: PedidoPos[]) => {
  return order.map((it) => ({
    pedidoPOSId: it.pedidoPOSId,
    pedidoPOSPaiId: it.pedidoPOSPaiId,
    valorTotal: it.valorTotal,
    tipoPagamento: it.tipoPagamento,
    produtos: it.produtos,
    dadosTransacao: it.dadosTransacao,
    tagCashless: it.tagCashless,
  }));
};

const consumeOrderToLog = (consumoPos: DadosConsumoPOS) => {
  return {
    accountId: consumoPos.accountId,
    orderId: consumoPos.order.orderId,
    items: consumoPos.order.items,
  };
};
