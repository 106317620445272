import { ICardMovement, IExtract } from "../../../domains/cashless/aggregates/IExtract";
import { IHttpRepository } from "../../interfaces/IHttpRepository";
import { ProdutoTipo } from "./PedidoMeep";
import { HttpRepository } from "./_HttpRepository";

export const ExtractRepository = () => {
  const httpRepository: IHttpRepository = HttpRepository();

  const _getSummaryExtract = async (comandaId: string, localId: string): Promise<CommandEletronicExtract> => {
    return await httpRepository.getAsync<CommandEletronicExtract>(
      `/CommandEletronic/SummaryExtract/Account/${comandaId}/Store/${localId}`
    );
  };

  const getExtract = async (comandaId: string, localId: string): Promise<CommandEletronicExtract> => {
    return await httpRepository.getAsync<CommandEletronicExtract>(
      `/CommandEletronic/Extract/Account/${comandaId}/Store/${localId}`
    );
  };

  const ParseExtractDataToNewEtract = (newExtract: CommandEletronicExtract): IExtract => {
    let newMovimentacoes: ICardMovement[] = [];

    newExtract.orders.forEach((orderItem) => {
      orderItem.items.forEach((item) => {
        newMovimentacoes.push({
          Descricao: item.productType === ProdutoTipo.Kilo ? `${item.quantity} kg x R$${item.totalValue} - ${item.name}` : `${item.quantity} x ${item.name}`,
          Id: item.productId,
          Data: orderItem.createdAt,
          Debito: !orderItem.isPayment ? item.totalValue * item.quantity : undefined,
          Credito: orderItem.isPayment ? item.totalValue * item.quantity : undefined,
          IsServiceFee: item.isServiceFee,
          Type: item.productType,
          Quantity: item.quantity
          // Saldo: item.totalValue,
        });
      });
    });

    var serviceFeePaid = newMovimentacoes.map(item => item.IsServiceFee && item.Debito ? item.Debito : 0).reduce((prev, current) => ((prev ?? 0) + (current ?? 0)), 0)

    var serviceFee = newExtract.serviceFeeConfig.serviceFeeAmount - serviceFeePaid;

    const extractResponse: IExtract = {
      Saldo: (newExtract.totalToPayWithFee - serviceFee) * -1,
      Nome: newExtract.user?.name ?? "",
      Movimentacoes: newMovimentacoes,
      TaxaServico: serviceFee,
      // TaxaServico: newExtract.serviceFeeConfig.serviceFeeAmount,
      // TaxaServico: newExtract.totalToPayWithFee + newExtract.balance,
      MinimoConsumo: newExtract.minimumConsumption,

    };

    return extractResponse;
  };

  const ParseSummaryEtract = (newExtract: CommandEletronicExtract): IExtract => {
    let newMovimentacoes: ICardMovement[] = [];

    newExtract.orders.forEach((orderItem) => {
      orderItem.items.forEach((item) => {
        newMovimentacoes.push({
          Descricao: item.productType === ProdutoTipo.Kilo ? `${item.quantity} kg x R$${item.totalValue} - ${item.name}` : `${item.quantity} x ${item.name}`,
          Id: item.productId,
          Data: orderItem.createdAt,
          Debito: !orderItem.isPayment ? item.totalValue * item.quantity : undefined,
          Credito: orderItem.isPayment ? item.totalValue * item.quantity : undefined,
          IsServiceFee: item.isServiceFee,
          Type: item.productType,
          Quantity: item.quantity
          // Saldo: item.totalValue,
        });
      });
    });

    var serviceFeePaid = newMovimentacoes.map(item => item.IsServiceFee && item.Debito ? item.Debito : 0).reduce((prev, current) => ((prev ?? 0) + (current ?? 0)), 0)

    var serviceFee = newExtract.serviceFeeConfig.serviceFeeAmount - serviceFeePaid;

    const extractResponse: IExtract = {
      Saldo: newExtract.balance,
      Nome: newExtract.user?.name ?? "",
      Movimentacoes: newMovimentacoes,
      TaxaServico: serviceFee,
      // TaxaServico: newExtract.serviceFeeConfig.serviceFeeAmount,
      // TaxaServico: newExtract.totalToPayWithFee + newExtract.balance,
      MinimoConsumo: newExtract.minimumConsumption,

    };

    return extractResponse;
  };

  const getOldExtract = async (comandaId: string, localId: string): Promise<IExtract> => {
    const response = await getExtract(comandaId, localId);
    // console.log('getOldExtract', ParseExtractDataToNewEtract(response))
    return ParseExtractDataToNewEtract(response);
  };
  
  const getSummaryExtract = async (comandaId: string, localId: string): Promise<IExtract> => {
    const response = await _getSummaryExtract(comandaId, localId);
    // console.log('getSummaryExtract', ParseSummaryEtract(response))
    return ParseSummaryEtract(response);
  };

  return {
    getExtract,
    getOldExtract,
    getSummaryExtract,
    getSummaryExtractWithoutParse: _getSummaryExtract
  };
};

export interface CommandEletronicExtract {
  accountId: string;
  orders: {
    id: string;
    createdAt: Date;
    isPayment: boolean;
    isCanceled: boolean;
    isTransfered: boolean;
    items: {
      id: string;
      quantity: number;
      productId: string;
      name: string;
      totalValue: number;
      isCanceled: boolean;
      isTransfered: boolean;
      isServiceFee: boolean;
      productType: ProdutoTipo;
    }[];
  }[];
  totalAmount: number;
  totalDiscount: number;
  balance: number;
  paidAmount: number;
  minimumConsumption: number;
  totalToPayWithFee: number;
  totalToPayWithoutFee: number;
  serviceFeeConfig: { serviceFeeAmount: number; serviceFeePercentage: number };
  user?: { id: string; name: string; document: string };
  printerIP: string;
}
