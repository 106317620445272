export const hideCpf = (cpf: string) => {
  const cleanCPF = cpf.replace(/\D/g, '');

  if (cleanCPF.length !== 11) {
    return '';
  }

  return `***.***.*${cleanCPF.slice(7, 9)}-${cleanCPF.slice(9, 11)}`;
}

export const hideCnpj = (cnpj: string) => {
  if (cnpj.length !== 14) {
      return cnpj;
  }
  const firstTwo = cnpj.slice(0, 2);
  const lastTwo = cnpj.slice(-2);
  const middle = '.***.***/****-';

  return `${firstTwo}${middle}${lastTwo}`;
}

export const formatCNPJ = (cnpj: string) => {
  cnpj = cnpj.replace(/\D/g, '');

  return cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    '$1.$2.$3/$4-$5'
  );
}


export const toInputDateString = (date: Date | string) => {
    date = date instanceof Date ? date : new Date(date);
    return `${date.toLocaleDateString("pt-BR", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    })} - ${date.toLocaleTimeString([], {      
      hour12: false
    })}`;
  }

export const roundValue = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export const toDateAndTime = (date: string) => {
  const adjustedDate = !date.endsWith("Z") ? `${date}Z` : date;
  return `${new Date(adjustedDate).toLocaleDateString([], {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  })} - ${new Date(adjustedDate).toLocaleTimeString([], {
    hour12: false,
  })}`;
}