import { format } from "date-fns";
import QRCode from "qrcode.react";
import React, { FC } from "react";
import { formatCNPJ, hideCpf } from "utils/utils";
import { Kiosk } from "../../../domains/kiosk/aggregates/Kiosk";
import { EletronicInvoiceTicket } from "../../../domains/order/agreggates/EletronicInvoiceTicket/EletronicInvoiceTicket";
import "./InvoiceLayoutPrinter.css";
interface InvoiceLayoutPrinterProps {
  invoice: EletronicInvoiceTicket;
  kiosk: Kiosk;
}

export const InvoiceLayoutPrinter: FC<InvoiceLayoutPrinterProps> = ({
  invoice,
  kiosk,
}) => {
  const serviceFee = invoice.itens.find((it) => it.isServiceFee);
  return (
    <div id="invoiceLayoutPrinter">
      <div className="title">{kiosk.localName}</div>
      <div className="sub-title">{formatCNPJ(kiosk.document)}</div>
      <div className="sub-title">{invoice.razaosocial}</div>

      <div className="invoice-divisor" />

      <div className="info">
        DANFE NFC-e
        <br />
        Documento auxiliar da Nota Fiscal do Consumidor eletrônica
      </div>

      <div className="invoice-divisor" />

      <div className="info">
        Consulte no SEFAZ do seu estado através do
        <br />
        QRCode ou utilize o código
      </div>
      <QRCode className="qrCode" size={200} value={invoice?.danfe ?? ''} />
      <div className="info-b">{invoice.key}</div>

      <div className="invoice-divisor" />

      <div className="sub-title">
        Produtos
      </div>

      <div className="products">
        <div>Nome</div>
        <div>Qtd.</div>
        <div>Valor Un.</div>
        <div>Total</div>
      </div>

      {invoice.itens
        .filter((it) => !it.isServiceFee)
        .map((item, key) => (
          <div key={key} className="products">
            <div>{item.name}</div>
            <div>{item.quantity}</div>
            <div>R${(item?.value ?? 0).toFixed(2).replace(".", ",")}</div>
            <div>
              R$
              {((item?.value ?? 0) * item.quantity)
                .toFixed(2)
                .replace(".", ",")}
            </div>
          </div>
        ))}

      {serviceFee && (
        <div className="item">
          <div>Taxa de serviço</div>
          <div>R${serviceFee.value.toFixed(2).replace(".", ",")}</div>
        </div>
      )}

      <div className="item-b">
        <div>Valor total</div>
        <div>
          <b>R${invoice.totalValue.toFixed(2).replace(".", ",")}</b>
        </div>
      </div>

      {invoice.infoInteresseContribuinte && (
        <>
          <div className="invoice-divisor" />
          <div className="info">
            {invoice.infoInteresseContribuinte}
          </div>
        </>
      )}

      <div className="invoice-divisor" />

      <div className="item">
        <div>Consumidor</div>
        <div>{invoice.client ? hideCpf(invoice.client) : "Não informado"}</div>
      </div>

      <div className="item">
        <div>NFC-e</div>
        <div>{`${invoice.numbering} • Série ${invoice.serie}`}</div>
      </div>

      <div className="item">
        <div>Data</div>
        <div>
          {format(new Date(invoice.emissionDate), "dd/MM/yyyy '•' HH:mm")}
        </div>
      </div>


      {invoice.infoInteresseFisco && (
        <>
          <div className="invoice-divisor" />
          <div className="info">
            {invoice.infoInteresseFisco}
          </div>
        </>
      )}
    </div>
  );
};
