

import { IEletronicInvoiceV2 } from "../../../domains/order/agreggates/EletronicInvoice/EletronicInvoice";
import { INFCeResult, IEletroniInvoiceV2Request } from "../../../domains/order/agreggates/EletronicInvoiceTicket/IEletronicInvoice";
import { IHttpRepository } from "../../interfaces/IHttpRepository";
import { ProductItem } from "../../../domains/order/agreggates/EletronicInvoiceTicket/ProductItem";
import enviroment from "../../../corss-cutting/enviroment";
import { IEletronicInvoiceRepository } from "../../../domains/order/interfaces/repositories/IEletronicInvoiceRepository";
import { EletronicInvoiceTicket } from "../../../domains/order/agreggates/EletronicInvoiceTicket/EletronicInvoiceTicket";
import { HttpRepository } from "./_HttpRepository";


/**
 * @deprecated
 */
export const EletronicInvoiceRepository = (
): IEletronicInvoiceRepository => {
  const httpRepository: IHttpRepository = HttpRepository()
  const base = enviroment.apiUrl;
  httpRepository.setBaseUrl(base);
  httpRepository.setTimeout(30000);


  const sendAsync = async (
    eletronicInvoice: IEletronicInvoiceV2
  ): Promise<EletronicInvoiceTicket[] | null> => {
    const request = getRequestV2(eletronicInvoice);
    console.log(request);
    const response = await httpRepository.postAsync<IEletroniInvoiceV2Request[], INFCeResult[]>("/v2/NFCe/Emitir", request);
    console.log('service',response)
   
      return getResult(response);
    
  }

  // const sendSplited = (eletronicInvoice: EletronicInvoice): Observable<Array<EletronicInvoiceTicket | undefined>> => {
  //   const request = getRequest(eletronicInvoice);
  //   return httpRepository
  //     .post<any, any>("/NFCe/EmitirSplit", request)
  //     .pipe(map((result) => result.map((item: any) => getResult(item))));
  // };

  // const sendSplitedAsync = async (eletronicInvoice: EletronicInvoice): Promise<Array<EletronicInvoiceTicket>> => {
  //   const request = getRequest(eletronicInvoice);
  //   const response: INFCeResult[] = await httpRepository.postAsync("/NFCe/EmitirSplit", request);
  //   return response.map((item: INFCeResult) => getResult(item)).filter(item => item)
  //   // return response.filter(item => item.sefaz.protocolo).map((item: INFCeResult) => getResult(item))
  // }


  const getRequestV2 = (eletronicInvoice: IEletronicInvoiceV2): any => {
    return {
      pedidoId: eletronicInvoice.pedidoId,
      documento: eletronicInvoice.documento,
      ehManual: false,
    };
  }

  // const getRequest = (eletronicInvoice: EletronicInvoice): any => {
  //   return {
  //     id: eletronicInvoice.id,
  //     razaoSocial: eletronicInvoice.corporateName,
  //     pedidoPaiId: eletronicInvoice.masterRequestId,
  //     // tokenCliente: eletronicInvoice.token,
  //     pedidoId: eletronicInvoice.requestId,
  //     vendedor: eletronicInvoice.seller,
  //     cliente: {
  //       cpf: eletronicInvoice.client.document,
  //       email: eletronicInvoice.client.email,
  //       nome: eletronicInvoice.client.name,
  //     },
  //     itens: eletronicInvoice.itens?.map((item) => {
  //       return {
  //         produto: item.id,
  //         quantidade: item.quantity,
  //         "valor-unitario": item.value,
  //         desconto: item.discount,
  //       };
  //     }),
  //     pagamentos: eletronicInvoice.payments.map((pay) => {
  //       return {
  //         tipo: pay.type,
  //         valor: pay.value,
  //       };
  //     }),
  //   };
  // };


  const getResult = (results: INFCeResult[]): EletronicInvoiceTicket[] | null => {
    const filteredResults = results.filter(result => result.sefaz && result.sefaz.chave);  

    if (filteredResults.length === 0) {
      return null;
    }
  
    const tickets: EletronicInvoiceTicket[] = [];
  
    filteredResults.forEach((result: INFCeResult) => {
      const ticket: EletronicInvoiceTicket = {
        razaosocial: result.razaosocial,
        companyDocument: result.cnpj,
        client: result.cliente,
        status: result.status,
        totalValue: parseFloat(result["valor-total"]),
        numbering: result.numero,
        serie: result.serie,
        emissionDate: result.sefaz.dataEmissao,
        danfe: result.sefaz["url-danfe"] ?? "",
        key: result.sefaz.chave,
        protocol: result.sefaz.protocolo,
        itens: result.itens.map((item: any) => {
          return {
            id: item.Produto,
            name: item.ProdutoNome,
            quantity: item.Quantidade,
            value: item.ValorUnitario,
          } as ProductItem;
        }),
        mustPrintCompleteDANFE: true,
        infoInteresseContribuinte: result["info-interesse-contribuinte"],
        infoInteresseFisco: result["info-interesse-fisco"]
      };
  
      tickets.push(ticket);
    });
  

    return tickets;
  };
  return { sendAsync };
};
